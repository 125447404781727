import { gql } from '@apollo/client';

// used for getting all packages
export const GET_ALL_PACKAGES = gql`
    query getAllPackages (
        $lastItemId: Int!
        $noOfItems: Int!
        $status: Boolean
    ) {
        getAllPackages (
            data: {
                lastItemId: $lastItemId
                noOfItems: $noOfItems
                status: $status
            }
        ) {
            data {
                id
                name
                isFree
                isPopular
                actualPrice
                packagePrice
                createdAt
                updatedAt
                status
                typeIcon
                packageFeatures {
                    id
                    feature {
                        id
                        name
                        price
                        featureMetrics {
                            id
                            metric {
                                id
                                name
                                price
                                description
                                createdAt
                                updatedAt
                                usages
                                status
                                isRecommended
                            }
                            usages
                            createdAt
                            updatedAt
                        }
                        createdAt
                        updatedAt
                        status
                    }
                    createdAt
                    updatedAt
                }
            }
            totalCount
        }
    }
`

// used for getting user package cart
export const GET_USER_PACKAGE_CART = gql`
    query getUserPackageCart {
        getUserPackageCart {
            id
            metrics {
                createdAt
                id
                isRecommended
                name
                price
                status
                updatedAt
            }
            package {
                actualPrice
                createdAt
                id
                name
                packageFeatures {
                    createdAt
                    feature {
                        createdAt
                        featureMetrics {
                            createdAt
                            id
                            metric {
                                createdAt
                                id
                                description
                                isRecommended
                                name
                                price
                                status
                                updatedAt
                            }
                            usages
                            updatedAt
                        }
                        id
                        name
                        price
                        status
                        updatedAt
                    }
                    id
                    updatedAt
                }
                packagePrice
                status
                updatedAt
            }
        }
    }
`

// used for getting all features
export const GET_ALL_FEATURES = gql`
    query getAllFeatures (
        $lastItemId: Int!
        $noOfItems: Int!
        $status: Boolean
    ) {
        getAllFeatures (
            data: {
                lastItemId: $lastItemId
                noOfItems: $noOfItems
                status: $status
            }
        ) {
            features {
                createdAt
                featureMetrics {
                    createdAt
                    id
                    metric {
                        createdAt
                        description
                        id
                        isRecommended
                        name
                        price
                        status
                        updatedAt
                        usages
                    }
                    usages
                    updatedAt
                }
                id
                name
                price
                status
                updatedAt
            }
            totalCount
        }
    }
`

// used for getting all features for specific package
export const GET_ADDON_FEATURE = gql`
    query getAddonFeature (
        $filterId: Int
        $lastItemId: Int!
        $noOfItems: Int!
    ) {
        getAddonFeature (
            data: {
                filterId: $filterId
                lastItemId: $lastItemId
                noOfItems: $noOfItems
            }
        ) {
            features {
                createdAt
                featureMetrics {
                    createdAt
                    id
                    metric {
                        createdAt
                        description
                        id
                        isRecommended
                        name
                        price
                        slug
                        status
                        updatedAt
                        usages
                    }
                    usages
                    updatedAt
                }
                id
                name
                price
                status
                updatedAt
            }
            totalCount
        }
    }
`

// used for getting checkout token
export const GET_CHECKOUT_TOKEN = gql`
    query getCheckOutToken {
        getCheckOutToken
    }
`

// used for getting user subscription info
export const GET_USER_SUBSCRIPTION = gql`
    query getUserSubscription {
        getUserSubscription {
            addOns {
                feature {
                    id
                    isAddon
                    isRecommended
                    metrics {
                        id
                        isAddon
                        isRecommended
                        name
                        pendingUsages
                        price
                        slug
                        usages
                    }
                    name
                    price
                }
                featureId
                id
                isAddon
                price
            }
            addonsMetrics
            addonsPrice
            addonsToAdd
            addonsToRemove
            billingId
            collection_method
            createdAt
            discountInfo {
                couponAmountOf
                couponCurrency
                couponId
                couponPercentOf
                discountId
                discountValue
            }
            downGradePackageId
            entityEmail
            entityRegNo
            entityType
            entityValue
            id
            invoice_paymentIntentSecret
            invoice_payment_intent_id
            invoice_status
            isAutoRenew
            isCanceled
            isCurrentPlan
            isFree
            isOnHold
            latest_invoice_URL
            nextDueOn
            packageActiveTill
            packageIcon
            packageId
            packageName
            packagePrice
            paymentMethod {
                allow_redisplay
                billing_details {
                    address {
                        city
                        country
                        line1
                        line2
                        postal_code
                        state
                    }
                    email
                    name
                    phone
                }
                card {
                    brand
                    checks {
                        address_line1_check
                        address_postal_code_check
                        cvc_check
                    }
                    country
                    display_brand
                    exp_month
                    exp_year
                    fingerprint
                    funding
                    last4
                    networks {
                        available
                        preferred
                    }
                    three_d_secure_usage {
                        supported
                    }
                }
                created
                id
                livemode
                object
                type
            }
            payment_intentId
            price
            promoCodeDiscount
            promoCodeId
            serviceCharge
            status
            subscriptionFeatures {
                feature {
                    id
                    isAddon
                    isRecommended
                    metrics {
                        id
                        isAddon
                        isRecommended
                        name
                        pendingUsages
                        price
                        usages
                    }
                    name
                    price
                }
                featureId
                id
                isAddon
                price
            }
            taxInfo {
                inclusive
                rate
                taxAmount
                taxability_reason
                taxable_amount
            }
            upGradePackageId
            upcomingInvoiceInfo {
                amountDue
                billing_reason
                collectionMethod
                dueDate
            }
            updatedAt
            userId
        }
    }
`

// used for getting metric details for current subscribed package
export const GET_USER_SUBSCRIPTION_METRIC_USAGES_BY_SLUG = gql`
    query getUserSubscriptionMetricUsagesBySlug (
        $slug: String!
    ) {
        getUserSubscriptionMetricUsagesBySlug (
            slug: $slug
        ) {
            id
            isAddon
            isRecommended
            name
            pendingUsages
            price
            slug
            usages
        }
    }
`

// used for getting create product daily limit
export const GET_CREATE_PRODUCT_DAILY_LIMIT = gql`
    query getCreateProductDailyLimit {
        getCreateProductDailyLimit {
            id
            isAddon
            isRecommended
            name
            pendingUsages
            price
            slug
            usages
        }
    }
`