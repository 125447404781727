import moment from 'moment';

// color list for ignite over time chart
export const igniteOverTimeChartColorList = [
    "#E31069",
    "#088AB2",
    "#7839EE",
    "#EC4A0A",
    "#BA24D5",
    "#008DEB",
    "#155EEF",
    "#CA8504",
    "#099250",
    "#FC7400"
]

/**
    * @function lazyRetry
    * @params componentImport
    * @description used to retry loading a chunk to avoid chunk load error for out of date code
*/
export const lazyRetry = (componentImport) => {
    return new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
        );
        // try to import the component
        componentImport().then((component) => {
            window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
            resolve(component);
        }).catch((error) => {
            if (!hasRefreshed) { // not been refreshed yet
                window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
                return window.location.reload(); // refresh the page
            }
            reject(error); // Default error behaviour as already tried refresh
        });
    });
}

/**
    * @function getNameFromSlug
    * @params str
    * @description used to get name from slug
*/
export const getNameFromSlug = (str) => {
    if (str) {
        return str.split('-').map((part) => {
            if (part === 'and') {
                return part;
            } else if (part === 'n') {
                return "&";
            } else {
                return part?.charAt(0)?.toUpperCase() + part?.slice(1);
            }
        }).join(' ');
    } else {
        return ''
    }
}

/**
    * @function getSlug
    * @params str
    * @description used to get slug
*/
export const getSlug = (str) => {
    if (str) {
        return str?.replaceAll(' ', '-')?.toLowerCase()?.replaceAll('&', 'n')?.replace(/[^A-Za-z^0-9]/g, "-")?.replace(/([^a-zA-Z0-9])\1+/mg, '$1');
    } else {
        return '';
    }
}

/**
    * @function convertNumber
    * @params n
    * @description convert the number into short form
*/
export const convertNumber = (n) => {
    if (n >= 0) {
        if (n < 1e3) {
            return n;
        } else if (n >= 1e3 && n < 1e6) {
            return +(n / 1e3).toFixed(1) + "K";
        } else if (n >= 1e6 && n < 1e9) {
            return +(n / 1e6).toFixed(1) + "M";
        } else if (n >= 1e9 && n < 1e12) {
            return +(n / 1e9).toFixed(1) + "B";
        } else if (n >= 1e12) {
            return +(n / 1e12).toFixed(1) + "T";
        } else {
            return n;
        }
    } else {
        return ''
    }
}

/**
    * @function range
    * @params start, stop, step
    * @description for getting the range of arrays in specific range with step
*/
export const range = (start, stop, step) => {
    if (typeof stop == 'undefined') {
        // one param defined
        stop = start;
        start = 0;
    }

    if (typeof step == 'undefined') {
        step = 1;
    }

    if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
        return [];
    }

    var result = [];
    for (var i = start; step > 0 ? i < stop : i > stop; i += step) {
        result.push(i);
    }

    return result;
}

/**
    * @function getLastWeekDate
    * @params
    * @description used to get last week date
*/
export const getLastWeekDate = () => {
    let dates = [];
    const NUM_OF_DAYS = 7; // get last 12 dates.

    for (let i = NUM_OF_DAYS; i > 0; i--) {
        dates.push(moment().subtract(i, 'day').format('DD/MM'));
    }
    return dates;
}

/**
    * @function getDateRange
    * @params
    * @description used to get date range between two date
*/
export const getDateRange = (firstDate, lastDate) => {
    if (moment(firstDate, 'YYYY-MM-DD').isSame(moment(lastDate, 'YYYY-MM-DD'), 'day')) {
        return [moment(lastDate).format('DD/MM')];
    }
    let date = firstDate;
    const dates = [moment(date).format('DD/MM')];
    do {
        date = moment(date).add(1, 'day');
        dates.push(date.format('DD/MM'));
    } while (moment(date).isBefore(lastDate));
    return dates;
}

/**
    * @function getMonthRange
    * @params
    * @description used to get month range between two date
*/
export const getMonthRange = (firstDate, lastDate) => {
    let startDate = moment(firstDate, "YYYY-M-DD");
    let endDate = moment(lastDate, "YYYY-M-DD").endOf("month");

    let allMonthsInPeriod = [];

    while (startDate.isBefore(endDate)) {
        allMonthsInPeriod.push(startDate.format("YYYY-MM"));
        startDate = startDate.add(1, "month");
    };
    return allMonthsInPeriod;
}

/**
    * @function matchYoutubeUrl
    * @params url
    * @description used to check youtube url
*/
export const matchYoutubeUrl = (url) => {
    var p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (url.match(p)) {
        return url.match(p)[1];
    }
    return false;
}