import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { AiFillCloseCircle } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";

const MySwal = withReactContent(Swal)

const ErrorAlert = (message) => {
    MySwal.fire({
        html: (
            <div className="d-flex justify-content-between align-items-start bg-error-toast text-white rounded-1 p-3">
                <div className="d-flex align-items-center">
                    <AiFillCloseCircle className='me-2' size={20} />
                    <div className='d-flex flex-column align-items-start'>
                        <span className='fw-bold'>ERROR</span>
                        <span className='text-start'>{message || 'Something went wrong'}</span>
                    </div>
                </div>
                <IoMdClose role='button' size={20} onClick={() => {
                    MySwal.close();
                }} />
            </div>
        ),
        position: 'top',
        timer: 3000
    })
}

export default ErrorAlert;